
.c-profile {

    &__title {
        font-size: 1.6rem;
        margin: 0 0 1rem 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__img {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        background: $color-yellow-4;
        overflow: hidden;
        border: 2px solid $color-white;
        padding-top: 0.5rem;
    }

    &__btn {
        flex: 0 1 calc(100% - 12rem);

        .btn-md {
            padding: 0;
            text-align: center;
            width: 100%;
            padding: 0 0.4rem;
        }
    }

    &__body {
        margin-bottom: 2rem;
    }
}

.profile-pics {
    background: $color-white;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    &.is-shown {
        overflow: auto;
        opacity: 1;
        margin-top: 1.5rem;
        padding: 1.5rem 2.5rem;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__item {
        flex: 0 1 30%;
    }

    &__img {
        width: 7.5rem;
        height: 7.5rem;
        border-radius: 50%;
        background: $color-yellow-4;
        overflow: hidden;
        border: 2px solid $color-white;
        padding-top: 0.3rem;
        margin-bottom: 1.5rem;

        &--selected {
            box-shadow: 0 0 0 3px #ff0000;
        }
    }
}

// profile-form
#profile-pics-form {
    font-weight: bold;
    margin: 0;

    .post-form__top {
        margin-bottom: 0;
    }

    .post-form__select {
        padding: 1rem 2rem 0.7rem;
        font-size: 1.3rem;
        border-radius: 1.5rem;
    }

    .post-form__img-preview {
        width: 4rem;
        height: 4rem;
    }
}

.c-profile-form {
    margin-top: 2.5rem;
}