
.modal-eco-giveaway__title {
    margin: 0;
    text-align: center;
    font-size: 1.6rem;
}

.eco-giveaway {
    margin: 1rem 0 2rem;

    &__item {
        box-shadow: 0 0.2rem 0 0 rgba($color-black, 0.4);
        border-radius: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.3;
        padding: 1.2rem;
        overflow: hidden;
        color: $color-black;
        background: $color-white;

        &:not(:first-child) {
            margin-top: 1.5rem;
        }

        &.is-disabled {
            position: relative;
            background: $color-secondary;
            opacity: 0.6;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($color-black, 0.3);
            }
        }
    }

    &__img {
        flex: 0 1 calc(18% - 0.7rem);
        text-align: center;

        img {
            width: 5rem;
        }
    }

    &__text {
        flex: 0 1 calc(52% - 0.7rem);
        word-break: break-all;
        font-size: 1.4rem;
    }

    &__point {
        flex: 0 1 calc(30% - 0.7rem);
    }

    &__number {
        @include flex-center;
        font-size: 2.4rem;
        color: $color-primary;

        img {
            width: 2.3rem;
            margin-right: 0.4rem;
        }
    }

    &__people {
        font-size: 1rem;
        height: 1.8rem;
        line-height: 1.8rem;
        padding: 0 1rem;
        text-align: center;
        border-radius: 2rem;
        background: rgba($color-black, 0.15);
    }
}