.login-screen__wrapper {
    background: url(../img/background.jpg) center no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    border: 0;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0;
}

// logo
.brand-logo {
    margin: 0 3.5rem 2.5rem;
    text-align: center;
}

// login form
.login-form {
    padding: 0 4rem;

    &__input {
        padding: 1rem 1.5rem;
        margin-bottom: 1.8rem;
        box-sizing: border-box;
        border: 1px inset $color-secondary;
        border-radius: 0;
        background-color: $color-white;
        width: 100%;
        line-height: 2;

        &:focus {
            outline: 0;
            border: 1px solid $color-black-2;
        }
    }

    &__submit-btn {
        text-align: center;
    }
}

//signup
.account {
    @include flex-center-column;
    text-align: center;
    margin: 2.4rem 0;

    &__link {
        display: block;
        font-weight: bold;
        color: $color-black;
        text-decoration: none;
        border-bottom: 1px solid $color-black;

        &--forgot-password {
            margin: 1.8rem 0;
        }
    }

}

// third-party-login
.sns {
    background: rgba($color-white, 0.6);
    border-radius: 4px;
    margin: 0 1.8rem;
    padding: 1.5rem 2.2rem;
    font-size: 1.4rem;

    &__title {
        text-align: center;
        margin: 0;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    &__item {
        flex-basis: calc(50% - 0.5rem);
        margin-bottom: 0.5rem;
        border-radius: 4px;

        img {
            width: 2.2rem;
            margin-right: 1.5rem;
        }

        &--facebook {
            background-color: $color-facebook;
        }

        &--twitter {
            background-color: $color-twitter;

            img {
                width: 2.5rem;
            }
        }

        &--yahoo {
            background-color: $color-yahoo;
        }

        &--line {
            background-color: $color-line;

        }
    }

    &__link {
        display: flex;
        align-items: center;
        color: $color-white;
        padding: 0.7rem 1rem;
        text-decoration: none;
    }
}