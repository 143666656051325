.splash-screen {
    background: url(../img/background.jpg) center no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;

    &__btn {
        margin: 3.5rem 0 4.5rem;
    }

    &__campaign-logo {
        width: 11.5rem;
    }
}