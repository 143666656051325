
.giveaway-detail {
    text-align: center;
    font-weight: bold;
    margin: 1rem 0 4rem;
    padding: 0 2.5rem;

    &__main-img {
        text-align: center;

        img {
            background: $color-white;
            padding: 1.7rem 2.5rem;
        }
    }

    &__point {
        color: $color-primary;
        font-size: 3.5rem;
        margin-top: 1.5rem;

        img {
            width: 3rem;
        }
    }

    &__desc {
        font-size: 2.4rem;
    }

    &__btn {
        margin-top: 2rem;

        .btn-md {
            padding: 0 5rem;
            font-size: 1.6rem;
        }
    }

    &__remains {
        @include flex-center;
        font-size: 1.4rem;
        margin-top: 2rem;

        img {
            width: 2.5rem;
            margin: 0 0.5rem 0 1rem;
        }

        &-point {
            color: $color-primary;
            font-size: 2.4rem;
        }
    }
}