.modal-eco-complete {
    top: 9rem;
}

.eco-complete {
    &__title {
        margin: 0 auto;
        width: 90%;
        transform: translateY(-5rem);
    }

    &__main-img {
        text-align: center;
        margin-top: - 4rem;

        > img {
            width: 17rem;
            margin: 0 auto;
        }
    }

    &__desc {
        font-weight: bold;
        margin-top: 1rem;
    }

    &__rank {
        @include flex-center;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 4.5rem;
        padding: 1rem 2rem 2rem;
        text-align: center;
        color: $color-primary;

        > img {
            width: 3.2rem;
        }
    }

    &__btn {
        position: relative;
    }
}
