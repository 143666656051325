
.eco-confirm {
    font-weight: bold;

    &__main-img {
        padding: 0 2rem;
        text-align: center;

        img {
            height: 16rem;
        }
    }

    &__content {
        margin: 1.5rem 0;
    }

    &__flex-container {
        @include flex-center;
        margin: 0 0 1rem;
    }

    &__img {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background: $color-yellow-4;
        overflow: hidden;
        border: 1px solid $color-white;
        padding-top: 0.3rem;
        margin: 0 1rem 0 0;
    }

    &__title {
        font-size: 2.4rem;
        margin: 0;
    }

    &__btn {
        text-align: center;
        padding-top: 2rem;
        margin-bottom: 3rem;
        border-top: 3px dotted $color-yellow-2;
        
        .btn-lg {
            height: 5rem;
            line-height: 5rem;
        }
    }

}