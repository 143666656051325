
.eco-point-history__title {
    margin: 0;
    font-size: 1.6rem;
    text-align: center;
}

.current-point {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;

    &__label {
        font-size: 1.4rem;
    }

    &__value {
        color: $color-primary;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 2.7rem;
        }
    }

    &__number {
        font-size: 3.1rem;
        margin: 0 0.5rem;
    }
}

//eco history
.eco-history {
    font-weight: bold;
    margin-top: 0.5rem;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 1.7rem 0;
        border-top: 0.3rem dotted $color-yellow-2;

        &:last-child {
            border-bottom: 0.3rem dotted $color-yellow-2;
        }
    }

    &__date {
        flex: 0 1 calc(25% - 0.8rem);
        background: #ddd8c0;
        font-size: 1.3rem;
        line-height: 1.2;
        text-align: center;
        padding: 0.4rem 0;
    }

    &__detail {
        flex: 0 1 calc(52% - 0.8rem);
        font-size: 1.4rem;
        word-break: break-all;
    }

    &__point {
        color: $color-primary;
        flex: 0 1 calc(23% - 0.8rem);
        text-align: right;
    }

    // negative points
    &__item--negative &__detail,
    &__item--negative &__point {
        color: $color-purple;
    }
}

//announcements 
.eco-history__btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0 1rem 0;
}

.eco-history__btn {
    flex: 0 1 calc(50% - 1rem);
    height: 3.7rem;
    color: $color-white;
    font-weight: bold;
    font-size: 1.4rem;
    border: 0;
    border-radius: 2rem;
    background: #9d9d9d;
    padding: 0 1rem;

    &--primary {
        background: $color-primary;
    }
}