
.eco-details {
    font-weight: bold;
    margin: 5rem 0;

    &__title {
        font-size: 2.4rem;
        text-align: center;
        margin: 0 0 2rem;
    }

    &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    }

    &__img {
        width: 8rem;
    }

    &__desc{
        flex: 0 1 calc(100% - 9rem);
    }

    &__rank{
        @include flex-center;
        font-weight: bold;
        font-size: 3rem;
        padding: 2rem;
        text-align: center;
        color: $color-primary;
        border-bottom: 0.3rem dotted $color-yellow-2;

        > img {
            width: 3.2rem;
        }
    }


    &__btn{
        text-align: center;
        margin: 2rem 0 4rem;
    }
}