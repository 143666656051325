* {
	box-sizing: border-box;
}

html {
	font-size: 53%; // 1rem - 8.48px

	@include mq('phone-small') {
		font-size: 58%; //1rem = 9.28px
	}

	@include mq('phone-md') {
		font-size: 62.5%; //1rem = 10px
	}

	@include mq('phone-wide') {
		@include fluid-type(450px, 768px, 10px, 16px);
	}
}

body {
	font-family : 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif;
	background: $color-secondary;
	font-size: 1.6rem;
	line-height: 1.4;
}

.body--transparent {
	background: transparent;
}

.img-responsive {
	max-width: 100%;
	height: auto;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

a {
	text-decoration: none;
}

p {
	margin: 0;
}