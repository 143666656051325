
.announcement {
    margin-bottom: 2rem;
    
    &__item {
        margin-top: 1rem;
        padding-bottom: 2.5rem;
        border-bottom: 3px dotted $color-yellow-2;
        &:not(:first-child) {
            margin-top: 2.5rem;
        }
    }

    &__date {
        display: inline-block;
        height: 2.3rem;
        line-height: 2.3rem;
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        padding: 0 2.5rem;
        background: #ddd8c0;
        margin-bottom: 1.2rem;
        border-radius: 0.4rem;
    }

    &__title {
        font-size: 1.6rem;
        text-decoration: underline;
        margin: 0;

        > img {
            width: 1.6rem;
            margin-left: 0.4rem;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1rem;
    }

    &__desc {
        flex: 0 1 calc(100% - 8.5rem);
        font-size: 1.4rem;
    }

    &__img {
        width: 7.5rem;
    }


}