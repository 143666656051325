
.setting {
    margin-bottom: 2rem;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1.7rem;
        border-bottom: 3px dotted $color-yellow-2;
        margin-bottom: 1.7rem;
        font-weight: bold;

        &:first-child {
            margin-top: 0.5rem;
        }
    }

    &__label {
        font-size: 1.5rem;
        flex: 0 1 calc(100% - 13rem);
    }

    &__btn {
        background: #9d9d9d;
        border: 0;
        font-size: 1.4rem;
        height: 3rem;
        line-height: 3rem;
        width: 5.8rem;
        text-align: center;
        color: $color-white;
        border-radius: 2rem;

        &.is-active {
            background: $color-primary;
            box-shadow: 0 0.2rem rgba($color-black, 0.6);
        }

        &:focus {
            outline: none;
        }
    }

    &__info {
        text-align: center;
        padding: 0 2rem;
        font-weight: bold;
        font-size: 1.3rem;
    }
}

.setting-phone {
    background: $color-white;
    padding: 2rem;
    font-weight: bold;
    margin-top: 1.5rem;

    &__title {
        font-size: 1.5rem;
        color: #e60000;
        text-align: center;
        margin: 0 0 1rem;
    }

    &__item {
        font-size: 1.2rem;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}