
//user profile modal
.user-profile {
    @include flex-center-column;
    padding: 1.5rem 3rem;

    &__img {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        background: $color-yellow-4;
        overflow: hidden;
        border: 2px solid $color-white;
        padding-top: 0.5rem;
    }

    &__status {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        text-align: center;
        font-weight: bold;
        margin-top: 2rem;
    }

    &__name {
        flex: 0 1 calc(62% - 1.2rem);
    }

    &__rank {
        flex: 0 1 calc(38% - 1.2rem);
    }

    &__label {
        font-size: 1.2rem;
        background: #ddd8c0;
        text-align: center;
        padding: 0.5rem;
        border-radius: 0.4rem;
        margin-bottom: 0.8rem;
    }

    &__value {
        font-size: 1.9rem;

        &--primary {
            color: $color-purple;
            font-size: 3.6rem;
            line-height: 1;
        }
    }
}

//user-point
.user-point {
    background: $color-white;
    padding: 2.2rem;
    font-weight: bold;

    &-current {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem 1rem 2rem;
        border-bottom: 0.3rem dotted $color-yellow-2;

        &__label {
            @include flex-center;
            font-size: 1.5rem;

            img {
                width: 2rem;
                margin-right: 0.5rem;
            }
        }

        &__value {
            @include flex-center;
            font-size: 2.4rem;
            color: $color-primary;

            small {
                font-size: 1.2rem;
                color: $color-black;
                margin-left: 0.5rem;
            }
        }
    }

    &-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;
        margin: 1.2rem 0;

        &__label {
            @include flex-center;
            font-size: 1.2rem;
        }

        &__value {
            @include flex-center;
            font-size: 1.4rem;

            small {
                font-size: 1.2rem;
                margin-left: 0.5rem;
            }
        }
    }

    &__btn {
        text-align: center;
    }
}

//user-info
.user-info {
    margin: 2rem;
    font-weight: bold;

    &__label {
        font-size: 1.4rem;
        background: #ddd8c0;
        width: 7.5rem;
        height: 2.6rem;
        line-height: 2.6rem;
        text-align: center;
        margin-right: 1rem;
        border-radius: 0.4rem;
    }

    &__top {
        display: flex;
        padding-bottom: 1rem;
    }

    &__bottom {
        display: flex;

        &--right {
            display: flex;
            flex: 0 1 50%;
        }

        &--left {
            display: flex;
            flex: 0 1 50%;
        }

    }
}

// user btn
.user-btn {
    text-align: center;
    margin-bottom: 4rem;

    &__top {
        margin-bottom: 2rem;

        a {
            position: relative;

            >img {
                width: 1.5rem;
                position: absolute;
                top: 50%;
                right: 1.5rem;
                transform: translateY(-50%);

            }
        }
    }

    &__bottom {

        a {
            display: inline-block;
            font-weight: normal;
            line-height: 1;
            font-size: 1.4rem;
            color: $color-black;
            border-bottom: 1px solid $color-black;
        }
    }
}