
.b-game {
    font-weight: bold;
    padding-bottom: 4rem;
    position: relative;

    &__heading {
        margin-top: -6.5rem;
        padding: 0 2rem;

        &--full {
            padding: 0;
            margin: -6.5rem -2rem 0;
        }

        img {
            display: block;
        }
    }


    &__title {
        text-align: center;
        font-size: 1.8rem;
        margin: 1rem 0 2rem;
    }

    &__main-img {
        text-align: center;
        margin-top: 1.5rem;

        img {
            width: 7.2rem;
        }
    }
}

// cards
.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
    flex: 0 1 calc(25% - 0.8rem);
    margin-bottom: 1rem;

    img {
        display: block;
    }
}

// sdg
.sdg {
    position: absolute;
    right: 0;
    bottom: 0;
    background: $color-white;
    font-size: 1.2rem;
    font-weight: bold;
    height: 2.2rem;
    line-height: 2.2rem;
    padding: 0 4rem 0 2rem;
    border-radius: 2rem;

    &--text {
        font-size: 1.4rem;
    }

    &__img {
        position: absolute;
        width: 3.7rem;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

// game-time
.game-time {
    width: 100%;
    border-radius: 2rem;
    border: 0.3rem solid $color-yellow-3;
    margin: 3rem 0;
    background: $color-black;
    position: relative;

    &__progress-bar {
        display: block;
        background: $color-blue-4;
        height: 1rem;
        width: 60%;
    }

    &__clock {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2.5rem;
    }

    &__num {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $color-red;
        font-weight: bold;
        font-size: 2.1rem;
        text-shadow: -1px -1px 0 $color-white,
            1px -1px 0 $color-white,
            -1px 1px 0 $color-white,
            1px 1px 0 $color-white;
    }
}