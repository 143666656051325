.signup {
    background: url(../img/background.jpg) center no-repeat;
    background-size: cover;
    min-height: 100vh;

    &__wrapper {
        display: flex;
        border: 0;
        flex-direction: column;
        justify-content: center;
        padding: 3.5rem;
    }

    &__header {
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 1;
        padding: 2.5rem;
        text-align: center;
        color: $color-white;
        background: $color-blue-6;
    }
}

.field {
    margin-bottom: 2.5rem;

    &__label {
        display: block;
        font-size: 1.6rem;
        font-weight: bold;
        color: $color-black;
        margin: 0 0 0.7rem;
    }

    &__input,
    &__select {
        padding: 1rem 1.5rem;
        box-sizing: border-box;
        border: 1px inset $color-secondary;
        background-color: $color-white;
        border-radius: 0;
        font-size: 1.6rem;
        width: 100%;
        line-height: 2;

        &:focus {
            outline: 0;
            border: 1px solid $color-black-2;
        }
    }


    &__input {

        &::placeholder {
            color: $color-gray-2;
        }
    }

    &__select-wrapper {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 1.5rem;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-top: 1.5rem solid $color-gray;
        }
    }

    &__select {
        appearance: none;
        color: $color-gray-2;

        option {
            width: 100%;
            color: $color-black;
        }
    }

    &__submit-btn {
        text-align: center;
    }
}

//
.custom-checkbox {
    text-align: center;
    margin-bottom: 2.5rem;

    &__label {
        position: relative;
        font-weight: bold;
        margin-left: -2rem;

        &--underline {
            border-bottom: 1px solid $color-black;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        right: -3rem;
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 0.4rem;
        border: 1px solid $color-black;
        background-color: $color-white;

        &:after {
            content: "";
            display: none;
            position: absolute;
            left: 0.6rem;
            top: 0.1rem;
            width: 0.3rem;
            height: 1rem;
            border: solid $color-white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &__label:hover &__input~.checkmark {
        background-color: $color-white;
    }

    &__label:hover &__input:checked~.checkmark {
        background-color: $color-blue;
    }

    &__label &__input:checked~.checkmark:after {
        display: block;
    }
}