
.sponsor {
    font-weight: bold;
    margin: 1rem 0 2rem;

    &__desc {

        &--red {
            color: $color-red;
        }
    }

    &__headline {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 2.5rem;

        h4 {
            margin: 0;
            border-bottom: 2px solid $color-primary;
        }

        p {
            opacity: 0.5;
        }
    }
}

.sponsor-card {
    background: $color-white;
    border-radius: 1rem;
    overflow: hidden;
    margin-top: 2rem;

    &__header {
        background: $color-primary;
        text-align: center;
        padding: 1rem 1rem 0.7rem;

        img {
            width: 7rem;
        }
    }

    &__list {
        padding: 0 1.5rem;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.7rem 0;
        border-bottom: 0.3rem dotted $color-yellow-2;

        > img {
            width: 5.5rem;
        }
    }

    &__content {
        flex: 0 1 calc(100% - 7.5rem);
    }

    &__name {
        font-weight: bold;
        font-size: 1.5rem;
        margin: 0;
    }

    &__detail {
        font-weight: normal;
        font-size: 1.1rem;
    }


}