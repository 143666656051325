$color-primary: #3da290;

$color-secondary: #f9f7ec;

$color-accent: #ea3d3a;

$color-purple: #f08336;

$color-blue: #40a1df;
$color-blue-1: #355690;
$color-blue-2: #043459;
$color-blue-3: #7ccef5;
$color-blue-4: #269de2;
$color-blue-5: #1e398f;
$color-blue-6: #004788;

$color-white: #fff;
$color-black: #000;
$color-black-2: #5e5e5e;

$color-gray: #acacac;
$color-gray-2: #929292;

$color-yellow: #f5ee7c;
$color-yellow-2: #bea284;
$color-yellow-3:  #fbd9a0;
$color-yellow-4: #ffda78;
$color-yellow-5: #ddd8c0;

$color-red: #e5243b;
$color-red-2: #d01c01;

$color-facebook: #3d5a98;
$color-twitter: #0095e8;
$color-yahoo: #e64e4b;
$color-line: #26b300;