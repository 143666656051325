/*modal*/

.modal {
    display: block;
    // position: fixed;
    background: $color-secondary;
    padding: 0 0 0.5rem;
    // width: calc(100% - 3rem);
    // top: 3rem;
    // left: 1.5rem;
    z-index: 1;
    border-radius: 2rem;

    &--centered {
        top: 0 !important;
        background: none;
        border-radius: 0;
        height: 100vh;
    }

    &__wrapper {
        height: 80vh;
        overflow-y: scroll;
    }

    &__container {
        @include flex-center;
        align-items: flex-end;
        height: 100%;
        position: relative;
    }

    &__header {
        background: $color-primary;
        padding: 1.4rem 1rem;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        position: relative;
    }

    &__arrow {
        position: absolute;
        top: 40%;
        left: 2rem;
        border: solid $color-white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;

        &--left {
            transform: rotate(135deg);
        }
    }

    &__title {
        font-size: 2rem;
        margin: 0;
        text-align: center;
        color: $color-white;
    }

    &__body {
        position: relative;

        &--bg {
            background: $color-secondary;
            border-radius: 2rem;
        }

        &--p-15 {
            padding: 1.5rem;
        }

        &--p-20 {
            padding: 2rem;
        }
    }
}

.modal-iframe {
    display: block;
    position: fixed;
    background: rgba($color-black, 0.5);
    top: 0;
    height: 0;
    width: 100vw;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;

    &.is-active {
        opacity: 1;
        height: 100vh;
    }

    iframe {
        border: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        width: calc(100% - 3rem);
        top: 3rem;
        left: 1.5rem;
    }
}

//close btn
.close {
    position: absolute;
    bottom: calc(20vh - 7rem);
    left: 50%;
    transform: translateX(-50%);
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    border: 0.4rem solid $color-white;
    background: $color-purple;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1;
    color: $color-white;
    @include flex-center-column;

    &__img {
        font-size: 4rem;
        line-height: 0.5;
    }
}