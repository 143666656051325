
.eco-post {
    font-weight: bold;

    &__title {
        font-size: 2.4rem;
        text-align: center;
        margin: 1rem 0 2rem;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__img {
        width: 8rem;
    }

    &__desc {
        flex: 0 1 calc(100% - 9rem);
    }

    &__rank {
        @include flex-center;
        font-weight: bold;
        font-size: 3.5rem;
        padding: 1rem;
        text-align: center;
        color: $color-primary;
        border-bottom: 0.3rem dotted $color-yellow-2;

        >img {
            width: 3.2rem;
            margin-right: 0.5rem;
        }
    }


    &__btn {
        text-align: center;
        margin: 2rem 0 4rem;
    }
}

.post-form {
    margin: 1.5rem 0;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
    }


    &__select {
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        position: relative;
        color: $color-white;
        background-color: $color-primary;
        padding: 1rem 4rem;
        border-radius: 2rem;
        line-height: 1;
        box-shadow: 0 0.3rem 0 0 rgba(0,0,0, 0.5);

        input {
            cursor: pointer;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            filter: alpha(opacity=1);
            opacity: 0;
        }
    }

    &__img-preview {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        visibility: hidden;
        transition: visibility 0.3s;

        &.is-shown {
            visibility: visible;
        }
    }

    &__delete-btn {
        font-size: 1.2rem;
        padding: 0.6rem 1rem;
        line-height: 1;
        background: rgba(0,0,0, 0.2);
        border-radius: 2rem;
    }

    &__bottom {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 1rem;
            left: 1rem;
            width: 1.7rem;
            height: 2rem;
            background: url(../img/icon-pencil.png) 0 0 no-repeat;
            background-size: cover;
            filter: brightness(0);
        }
    }

    &__help-text {
        color: #e60000;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }

    &__textarea {
        width: 100%;
        font-weight: bold;
        padding: 1rem 1rem 1rem 3.5rem;
        border: inset 1px;
        border-radius: 0;
        font-size: 1.4rem;

        &__text-count {
            font-size: 1.2rem;
            font-weight: normal;
        }
    }
}