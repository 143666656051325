.main-wrapper {
    min-height: 100vh;
}

.header {

    &__top {
        flex: 0 1 36%;
        background: $color-primary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        color: $color-white;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        padding: 0.7rem;
    }
}

//history
.history {
    width: 36%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $color-white;
    font-size: 1.8rem;
    color: $color-black;
    height: 2.5rem;
    border: 0.15rem solid $color-white;
    border-radius: 3rem;

    &__coin {
        width: 2.6rem;
    }

    &__number {
        font-weight: bold;
    }

    &__text {
        @include flex-center;
        color: $color-white;
        background: $color-primary;
        font-size: 1.2rem;
        height: 100%;
        padding: 0.6rem;
        border-top-right-radius: 3rem;
        border-bottom-right-radius: 3rem;
    }
}

//giveaway 
.giveaway {
    flex: 0 1 36%;
    background: $color-primary;
    height: 2.5rem;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 1.4rem;
    line-height: 1;
    margin-top: 0.5rem;
    padding: 0 0.3rem;

    &__box {
        width: 2.5rem;
        margin-right: 1rem;
    }
}

//rank
.user {
    @include flex-center;
    font-weight: bold;
    font-size: 1.4rem;

    &__nick-name {
        color: $color-white;
    }

    &__icon-circle {
        display: inline-block;
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
        background: $color-white;
        position: relative;

        &:before {
            content: '';
            background: radial-gradient(circle at center, $color-primary 0%, $color-primary 30%, transparent 30%);
            background-size: 0.4rem 0.4rem;
            background-position: center;
            background-repeat: repeat-x;
            width: 1.3rem;
            height: 1.3rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__rank-label {
        font-weight: normal;
        font-size: 1.2rem;
        margin: 0 1rem;
    }

    &__rank-number {
        font-size: 3rem;
        line-height: 1;
    }
}


// prize
.prize {
    flex: 0 1 44%;
    background: $color-red-2;
    height: 2.5rem;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 1.4rem;
    line-height: 1;
    margin-top: 0.5rem;
    padding: 0 0 0 1.5rem;

    &__mail {
        width: 1.5rem;
        margin-right: 1rem;
    }
}

// daily bonus
.daily-bonus {

    >img {
        width: 5rem;
    }
}

//challenges
.challenges {
    background: $color-secondary url(../img/challenge-bg-sm.png) center bottom no-repeat;
    background-size: contain;
    padding: 0 1rem 3rem;
    position: relative;

    @include mq('phone') {
        background-image: url(../img/challenge-bg-md.png);
    }

    @include mq('phone-wide') {
        background-image: url(../img/challenge-bg.png);
    }
}

//eco mascot
.eco-mascot {
    @include flex-center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 3.5rem;

    &__item {
        flex: 0 1 calc(20% - 0.5rem);
    }

    &__main-img {
        height: 6rem;
    }

    &__title {
        height: 1.8rem;
        margin-top: 0.3rem;
    }
}

//progress-meter
.progress {
    margin-top: -1rem;
    position: relative;

    &__meter {
        height: 0.7rem;
        width: 85%;
        margin: 0 auto;
        background: $color-black;
        border-radius: 3rem;
        box-sizing: content-box;
        border: 0.3rem solid $color-white;
        box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.4);
        overflow: hidden;
    }

    &__bar {
        display: block;
        width: 30%;
        height: 100%;
        background: $color-purple;
    }

    &__heart {
        width: 1.8rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

// challenge
.challenge {
    font-weight: bold;

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        flex: 0 1 calc(50% - 0.7rem);
        background: $color-white;
        text-align: center;
        padding: 1.5rem 1rem;
        border-radius: 1.5rem;
        color: $color-black;
    }

    &__name {
        font-size: 2rem;
        margin: 0.2rem 0;
    }

    &__icon {
        width: 3rem;

        &--switch {
            width: 4rem;
        }
    }

    &__tackle {
        font-size: 1.4rem;
        text-decoration: none;
        color: $color-purple;

        img {
            width: 1.4rem;
        }
    }

}

// activity
.activity {
    background: $color-blue-1;
    padding: 2.5rem 1rem;
    margin-bottom: 6rem;

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        flex: 0 1 calc(33.3333% - 0.6rem);
        display: flex;
        position: relative;

        &--notify {
            position: absolute;
            top: -0.6rem;
            right: 1rem;
            height: 1.5rem;
            line-height: 1.5rem;
            background: $color-red-2;
            color: $color-white;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1rem;
            padding: 0 0.7rem;
            text-align: center;
            border-radius: 0.4rem;
        }
    }

    &__link {
        width: 100%;
        @include flex-center;
        text-align: center;
        padding: 0.2rem;
        font-weight: bold;
        font-size: 1.5rem;
        border-radius: 6px;
        color: $color-black;

        &--1 {
            background: $color-white;
        }

        &--2 {
            background: $color-blue-3;
        }

        &--3 {
            background: $color-yellow;
            font-size: 1.8rem;
            line-height: 1.2;
        }
    }
}

//banner {
.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    &__item {
        flex: 0 1 calc(33.3333% - 0.6rem);
    }

    &__link {
        display: block;
        border-radius: 0.6rem;
        overflow: hidden;

        img {
            display: block;
        }
    }

}

// footer
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $color-white;
}

// footer nav
.footer-nav {

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        flex: 1 1 20%;
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        height: 100%;
        font-weight: bold;
        font-size: 1rem;
        color: $color-blue-2;

        &.is-active {
            color: $color-white;
            background: $color-blue-2;
        }

        img {
            height: 2rem;
            margin-bottom: 0.7rem;
        }
    }
}