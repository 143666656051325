.btn {
    display: inline-block;
    padding: 1.2rem 6rem;
    font-weight: bold;
    font-size: 1.8rem;
    text-align: center;
    text-decoration: none;
    background: $color-white;
    color: $color-black;
    border: 0;
    border-radius: 3.5rem;
    box-shadow: 0px 0.2rem 0 0 rgba($color-black, 0.6);

    &--accent {
        background: $color-accent;
        color: $color-white;
        box-shadow: 0px 0.3rem 0 0 rgba($color-black, 0.6);
    }

    &--text-blue {
        color: $color-blue-5;
    }
}

.btn-sm {
    display: inline-block;
    background: #efefef;
    height: 2.7rem;
    line-height: 2.7rem;
    padding: 0 2rem;
    font-size: 1.3rem;
    border-radius: 2rem;
    color: $color-black;
    box-shadow:  0 0.2rem 0.1rem rgba(0,0,0, 0.4);
}

.btn-md {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    padding: 0 4.5rem;
    background: $color-primary;
    color: $color-white;
    font-weight: bold;
    font-size: 1.4rem;
    border-radius: 3rem;
    box-shadow: 0 0.3rem 0 rgba($color-black, 0.6);
    position: relative;
}

.btn-lg {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    padding: 0 3.5rem;
    background: $color-primary;
    color: $color-white;
    font-weight: bold;
    font-size: 2rem;
    border-radius: 3rem;
    box-shadow: 0 0.3rem 0 rgba($color-black, 0.6);
    position: relative;

    &--accent {
        background: repeating-linear-gradient(
            -45deg,
            rgba($color-accent, 0.8),
            rgba($color-accent, 0.8) 5px,
            $color-accent 5px,
            $color-accent 10px
          );
    }

    &--bubble {
        position: absolute;
        top: -1.5rem;
        background: $color-white;
        color: $color-black;
        font-size: 1.5rem;
        padding: 0 0.5rem;
        height: auto;
        line-height: 1;
        border-radius: 3rem;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -0.9rem; 
            transform: translateX(-50%);
            width: 0; 
            height: 0; 
            border-left: 0.6rem solid transparent;
            border-right: 0.6rem solid transparent;
            
            border-top: 1rem solid $color-white;
        }
    }

    &--highlight {
        color: $color-purple;
        font-size: 2.5rem;
    }

}